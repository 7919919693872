
import Login from './pages/Login';
import PickedUp from './pages/PickepUp';
import InTransit from './pages/InTransit';





import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/notfound" element={<Login />} />
        <Route path="/*" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/tracking_p/:tracking_id"  element={<PickedUp />} />
        <Route path="/tracking_t/:tracking_id"  element={<InTransit />} />
      </Routes>
    </Router>
  );

};

export default App;




import styled from 'styled-components';
import { mobile } from '../responsive';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import "../assets/css/tracking.css";
import Logo from '../assets/images/LOGO.png';
import StatusImage from '../assets/images/stage2.png';
import {TrackingNumbers, Packages} from '../data';


const Container = styled.div`
    width: 100%;
`;

export default function InTransit() {
    const location = useLocation();
    const navigate = useNavigate();
    const trackingId_ = location.pathname.split("/")[2];
    const [values, setValues] = useState({
        id: '',
        tracking_id: '',
        status: "",
        sender_name: "",
        sender_address: "",
        receiver_name: "",
        receiver_address: "",
        ship_date: "",
        origin: "",
        current_location: "",
        destination: "",
        standard_transit: "",
        service_type: "",
        number_of_pieces: "",
        item_weight: "",
        total_shipment_weight: ""
    });

    useEffect(() => {
        document.title = "In Transit - FST Courier";
        window.scrollTo(0, 0)

        if(trackingId_) {
            let regex = /^[a-zA-Z0-9]{10}$/;
            let formatIsValid = regex.test(trackingId_);
            if(formatIsValid){
                if (TrackingNumbers[trackingId_]) {
                    let _index = Number(TrackingNumbers[trackingId_].pass);
                    let _package = Packages[_index];
                    if(_package.status == "IN TRANSIT") {
                        setValues({ 
                            ...values, 
                            id: _package.id,
                            tracking_id: _package.tracking_id,
                            status: _package.status,
                            sender_name: _package.sender_name,
                            sender_address: _package.sender_address,
                            receiver_name: _package.receiver_name,
                            receiver_address: _package.receiver_address,
                            service_type: _package.service_type,
                            number_of_pieces: _package.number_of_pieces,
                            item_weight: _package.item_weight,
                            ship_date: _package.ship_date,
                            origin: _package.origin,
                            current_location: _package.current_location,
                            destination: _package.destination,
                            standard_transit: _package.standard_transit,
                            total_shipment_weight: _package.total_shipment_weight
                        });
                     }else {
                        navigate("/"); 
                     }
                }else {
                    navigate("/");
                }
            }else {
                navigate("/");
            }
        }else {
            navigate("/");
        }
    }, []);


    return (
        <Container>
            <div className="track-container">
            <div className="container-inner">
                    <section className="section-one">
                        <img src="https://arrabitocontainers.com/email_media/LOGO.png"/>
                    </section>
                <div className="container-inner-650">
                    <section className="section-two">
                        <h1>Hi. Your package is scheduled for delivery on {values.standard_transit}</h1>
                    </section>
                    <section className="section-four">
                        <h3>Scheduled delivery</h3>
                        <p className="four-p">{values.standard_transit}</p>
                        <img src="https://arrabitocontainers.com/email_media/stage2.png"/>
                        <h3>In Transit</h3>
                        <p className="four-p">{values.current_location}</p>
                        <div className="delivery-details">
                        <div className="item">
                            <div className="item-field"><span>Tracking number</span></div>
                            <div className="item-value"><span className="tracking-number-value">{values.tracking_id}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>From</span></div>
                            <div className="item-value">
                                <span>
                                {values.sender_name}<br />
                                {values.sender_address} 
                                </span>
                            </div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>TO</span></div>
                            <div className="item-value">
                                <span>{values.receiver_name}<br />
                                {values.receiver_address}
                                </span>
                            </div>
                            </div>
                        <div className="item">
                            <div className="item-field"><span>Ship Date</span></div>
                            <div className="item-value"><span>{values.ship_date}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>Origin</span></div>
                            <div className="item-value"><span>{values.origin}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>Destination</span></div>
                            <div className="item-value"><span>{values.destination}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>STANDARD TRANSIT</span></div>
                            <div className="item-value"><span>{values.standard_transit}</span></div>
                        </div>
            
                        <div className="item">
                            <div className="item-field"><span>Service type</span></div>
                            <div className="item-value"><span>{values.service_type}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>Number of pieces</span></div>
                            <div className="item-value"><span>{values.number_of_pieces}</span></div>
                        </div>
                        <div className="item">
                            <div className="item-field"><span>Total Shipment Weight</span></div>
                            <div className="item-value"><span>{values.total_shipment_weight}</span></div>
                        </div>
                        </div>
                    </section>
                    <section className="section-five">
                        <p>
                        © 2024 FST Courier Limited. The content of this message is protected by 
                        copyright and trademark laws under U.S. and international law. This email
                        has been sent to you in connection with the management of a shipment where 
                        you are the Consignee. FST Courier handles your data in accordance with our  
                        privacy policy. All rights reserved.
                        </p>
                        <p>
                        Have questions or need help? Email us at support@fstcourier.arrabitocontainers.com.
                        Please do not respond to this message. This is an automated message. 
                        If you no longer wish to receive communications relating to your 
                        shipments, you can unsubscribe at any time by clicking here
                        .</p>
                    </section>
                </div>
            </div>
            </div>
        </Container>
    );
}
